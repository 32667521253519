import React, { useContext } from 'react';
import { AppBar, Toolbar, Link, Typography, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { CartStateContext } from '../../store/StateProvider';
import { ShoppingCartBadge } from '../Badge/Badge';
import classNames from 'classnames';
import { headerStyle } from './HeaderStyle';
import { calculateTotalNumber } from '../../helpers/productsHelper';
import Img, { FixedObject } from 'gatsby-image';

const useStyles = makeStyles(headerStyle);

// #hmm https://github.com/gatsbyjs/gatsby/issues/16682
// const BlogPostLink = React.forwardRef<any, Omit<GatsbyLinkProps<any>, 'to'>>(function namedPostLink(props, ref) {
//   return <GatsbyLink ref={ref} to="/posts" {...props} />;
// });

interface HeaderProps {
  changeColorOnScroll?: {
    height: number;
    color: 'primary' | 'white';
  };
  color: 'transparent' | 'primary';
  absolute?: boolean;
  fixed?: boolean;
}

interface LogoAsset {
  file: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
}

export const Header: React.FC<HeaderProps> = ({ changeColorOnScroll, color, fixed, absolute }) => {
  const { products } = useContext(CartStateContext);
  const numberOfProducts = calculateTotalNumber(products);

  // @see https://www.gatsbyjs.org/docs/gatsby-image/ and https://www.gatsbyjs.org/docs/recipes/working-with-images/ for more info
  const data = useStaticQuery<LogoAsset>(graphql`
    query {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const classes = useStyles();
  const theme = useTheme();

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (changeColorOnScroll) {
      if (windowsScrollTop > changeColorOnScroll.height) {
        document.body.getElementsByTagName('header')[0].classList.remove(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.add(classes[changeColorOnScroll.color]);
      } else {
        document.body.getElementsByTagName('header')[0].classList.add(classes[color]);
        document.body.getElementsByTagName('header')[0].classList.remove(classes[changeColorOnScroll.color]);
      }
    }
  };

  React.useEffect(() => {
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return (): void => {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  });

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed,
  });

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Link className={classes.title} component={GatsbyLink} to="/" style={{ display: 'flex', alignItems: 'center' }}>
          <Img fixed={data.file.childImageSharp.fixed} alt="Logo" />
          <Box display={{ xs: 'none', md: 'block' }}>
            <Typography variant="h6" style={{ color: theme.palette.info.main, paddingLeft: '10px' }}>
              中文童书
            </Typography>
          </Box>
        </Link>
        {process.env.GATSBY_PREORDERING_STARTED === 'true' && (
          <Link component={GatsbyLink} to="/cart" style={{ float: 'right' }}>
            <ShoppingCartBadge quantity={numberOfProducts} />
          </Link>
        )}
        {/* TODO: BringBackPosts*/}
        {/*<List className={classes.list + ' ' + classes.mlAuto}>*/}
        {/*  <Box display={{ xs: 'none', lg: 'block' }}>*/}
        {/*    <ListItem className={classes.listItem}>*/}
        {/*      <Button color="info" component={GatsbyLink} to="/posts">*/}
        {/*        <FormattedMessage id="general.blogPosts" defaultMessage="Blog Posts" />*/}
        {/*      </Button>*/}
        {/*    </ListItem>*/}
        {/*  </Box>*/}
        {/*</List>*/}
      </Toolbar>
    </AppBar>
  );
};

export const DefaultHeader: React.FC = () => (
  <Header
    fixed
    color="transparent"
    changeColorOnScroll={{
      height: 100,
      color: 'white',
    }}
  />
);
